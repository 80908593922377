import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const CheckboxComponent = ({
  name,
  value,
  error,
  touched,
  option,
  label,
  onChange,
}) => {
  const intl = useIntl();

  return (
    <FormControl component="fieldset" error={Boolean(touched && error)}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormControlLabel
        key={option}
        control={
          <Checkbox
            checked={value && value.includes(option)}
            onChange={onChange}
            name={name}
            value={option}
          />
        }
        label={intl.formatMessage({
          id: `REQUEST_FROM.${option}`,
        })}
      />
      <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
  );
};

CheckboxComponent.defaultProps = {
  touched: false,
  error: false,
  value: null,
  label: null,
};

CheckboxComponent.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool,
  touched: PropTypes.bool,
  option: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxComponent;
