const translationDict = {
  email: 'Email',
  name: 'Nome',
  phone_number: 'Telefone',
  country_id: 'Bandeira',
  imo: 'IMO',
  width: 'Largura (m)',
  length: 'Comprimento (m)',
  registration_port: 'Porto de Registro',
  ship_type_id: 'Tipo de Navio',
  year: 'Ano de Fábrico',
  gross_tonnage: 'Tonelagem Bruta (T)',
  PASSENGER: 'Passageiros',
  CONTAINER: 'Contentor',
  PLEASURE_CRAFT: 'Embarcaçāo de Recreio',
  GENERAL_CARGO: 'Carga Geral',
  FISHING: 'Pesca',
  TANKER: 'Tanker',
  OTHER: 'Outro',
  BOILERMAKING: 'Caldeiraria',
  CARPENTRY: 'Carpintaria',
  ELECTRICITY: 'Eletricidade',
  NON_DESTRUCTIVE_TESTING: 'Ensaios Não Destrutivos',
  MECHANICS: 'Mecânica',
  MECHANICAL_LOCKSMITHING: 'Serralharia Mecânica',
  HULL_TREATMENT: 'Tratamento De Casco',
  entry_date: 'Data de Entrada',
  exit_date: 'Data de Saída',
  shipowner_name: 'Nome da Empresa',
  shipowner_fiscal_number: 'Número de Identificação Fiscal',
  shipowner_email: 'Email',
  shipowner_phone_number: 'Telefone',
  shipowner_cell_phone: 'Telemóvel',
  shipowner_street: 'Rua',
  shipowner_city: 'Cidade',
  shipowner_country: 'Pais',
  shipowner_mailbox: 'Caixa Postal',
  full_name: 'Nome Completo',
  cell_phone: 'Telemóvel',
  country: 'País de Origem do Preenchimento do Formulário',
  position_within_the_company: 'Posiçāo Dentro da Empresa',
  ship_information_imo: 'IMO',
  ship_information_name: 'Nome',
  ship_information_country: 'Bandeira',
  ship_information_registration_port: 'Porto de Registo',
  ship_information_building_yards_name: 'Nome do Estaleiro de Fabrico',
  ship_information_building_yards_country: 'País do Estaleiro de Fabrico',
  ship_information_year: 'Ano De Fabrico',
  ship_information_ship_type_name: 'Tipo de Navio',
  ship_information_ship_class_and_classification_society:
    'Classe e Entidade Classificadora',
  ship_information_length: 'Comprimento (m)',
  ship_information_overall_length: 'Comprimento fora-fora (m)',
  ship_information_width: 'Dimensāo Total da Boca (m)',
  ship_information_light_ship_draft: 'Calado do Navio na Condiçāo Leve (m)',
  ship_information_light_displacement: 'Deslocamento leve (T)',
  ship_information_maximum_displacement: 'Deslocamento máximo (T)',
  ship_information_draft: 'Calado (m)',
  ship_information_depth: 'Pontal (m)',
  ship_information_propulsion_system_type_id: 'Tipo de Sistema Propulsivo',
  ship_information_onboard_electrical_power_system_voltage:
    'Voltagem do Sistema de Alimentaçāo Elétrica a Bordo',
  ship_information_onboard_electrical_power_system_frequency:
    'Frequência do Sistema de Alimentaçāo Elétrica a Bordo (Hz)',
  VARIABLE_PITCH: 'Passo Variável',
  FIXED_STEP: 'Fixo',
};

export default translationDict;
