import { Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';

import animationData from '../../assets/lottiefiles/404.json';
import CustomButton from '../../components/Button/CustomButton';
import SectionHeader from '../../components/SectionHeader';
import Wrapper from '../../components/Wrapper';

const SuccessSubmit = () => {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
  };

  const handleNavigateToCreateRequest = () => {
    navigate('/');
  };

  return (
    <>
      <Helmet>
        <title>Solicitaçāo Submetida com Sucesso | Cabnave</title>
      </Helmet>
      <Wrapper>
        <SectionHeader
          title="Solicitaçāo Submetida"
          description="Sua solicitaçāo foi submetida com sucesso"
          isPrimary
        />
        <Container maxWidth="sm">
          <Box sx={{ mt: 3 }}>
            <Lottie options={defaultOptions} height={350} width={350} />
            <CustomButton
              onClick={() => handleNavigateToCreateRequest()}
              title="Nova Solicitaçāo"
            />
          </Box>
        </Container>
      </Wrapper>
    </>
  );
};

export default SuccessSubmit;
