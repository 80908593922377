import { CloudCircle, RemoveCircle } from '@mui/icons-material';
import {
  Box,
  Typography,
  Input,
  Button,
  List,
  ListItem,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import theme from '../../../theme';
import ContentCard from '../../ContentCard';

const UploadDocuments = ({ values, selectedFiles, setSelectedFiles }) => {
  const intl = useIntl();

  const [filePreviews, setFilePreviews] = useState([]);

  const handleFileChange = (event) => {
    const { files } = event.target;

    const updatedSelectedFiles = [...selectedFiles, ...files];
    setSelectedFiles(updatedSelectedFiles);

    const updatedPreviews = Array.from(updatedSelectedFiles).map((file) => ({
      name: file.name,
    }));
    setFilePreviews(updatedPreviews);
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedSelectedFiles = selectedFiles.filter(
      (file) => file !== fileToRemove,
    );
    setSelectedFiles(updatedSelectedFiles);

    const updatedPreviews = Array.from(updatedSelectedFiles).map((file) => ({
      name: file.name,
    }));
    setFilePreviews(updatedPreviews);
  };

  const renderFilePreviews = () => {
    if (filePreviews.length === 0) {
      return null;
    }

    return (
      <Box>
        <ContentCard>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body1">
                {intl.formatMessage({
                  id: 'REQUEST_FORM.UPLOAD_DOCUMENTS.UPLOADED_DOCUMENTS',
                })}
              </Typography>
            </Box>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {filePreviews.map((file, index) => (
                <ListItem
                  key={file.name}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom:
                      index !== filePreviews.length - 1
                        ? `1px solid ${theme.palette.divider}`
                        : 'none',
                    py: 1,
                  }}
                >
                  <Typography overflow="hidden" variant="body3">
                    {file.name}
                  </Typography>
                  <IconButton
                    onClick={() => handleRemoveFile(selectedFiles[index])}
                  >
                    <RemoveCircle
                      fontSize="small"
                      sx={{ color: theme.palette.error.main }}
                    />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </ContentCard>
      </Box>
    );
  };

  return (
    <Box>
      <Box>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'REQUEST_FORM.UPLOAD_DOCUMENTS',
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
        }}
      >
        <Button
          component="label"
          variant="outlined"
          startIcon={<CloudCircle />}
          sx={{ position: 'relative' }}
        >
          {intl.formatMessage({
            id: 'REQUEST_FORM.UPLOAD_DOCUMENTS.UPLOAD_BUTTON',
          })}
          <Input
            sx={{
              height: 0,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'absolute',
              left: 0,
              right: 0,
            }}
            type="file"
            inputProps={{ multiple: true }}
            onChange={handleFileChange}
            value={values.files}
          />
        </Button>
        {renderFilePreviews()}
      </Box>
    </Box>
  );
};

UploadDocuments.propTypes = {
  values: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
};

export default UploadDocuments;
