import { useIntl } from 'react-intl';
import * as Yup from 'yup';

const useSchemaValidation = () => {
  const intl = useIntl();

  const COMMON_MESSAGE = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    number: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.NUMBER',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
    leadingSpacesStart: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.NO_LEADING_SPACES_START',
    }),
    leadingSpacesStartAndEnd: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.NO_LEADING_SPACES_START_AND_FINAL',
    }),
  };

  const FULL_NAME_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const EMAIL_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    email: intl.formatMessage({
      id: 'YUP.VALIDATION.VALID_EMAIL',
    }),
  };

  const PHONE_NUMBER_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const POSITION_WITHIN_COMPANY_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const COUNTRY_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const SHIP_TYPE_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const OTHER_SHIP_TYPE_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const IMO_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    minCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.SHIP_VALID_IMO.MIN_CHARACTERS',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.SHIP_VALID_IMO.MAX_CHARACTERS',
    }),
  };

  const SHIP_NAME_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const REGISTRATION_PORT_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const MAXIMUM_DISPLACEMENT_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const LIGHT_DISPLACEMENT_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const SHIP_LENGTH_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    number: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.NUMBER',
    }),
  };

  const SHIP_OVERALL_LENGTH_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    number: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.NUMBER',
    }),
  };

  const SHIP_WIDTH_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    number: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.NUMBER',
    }),
  };

  const SHIP_YEAR_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    exactDigits: intl.formatMessage({
      id: 'YUP.VALIDATION.SHIP_VALID_YEAR.EXACT_DIGITS',
    }),
  };

  const SHIP_DRAFT_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const SHIP_DEPTH_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const SHIP_PROPULSION_SYSTEM_TYPE_ID = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const CLASS_AND_CLASSIFYING_ERROR_MESSAGES = {
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const SHIP_LIGHT_SHIP_DRAFT_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const SHIP_ON_BOARD_ELECTRICAL_POWER_VOLTAGE_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const SHIP_ON_BOARD_ELECTRICAL_POWER_FREQUENCY_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const SHIP_BUILDING_YARDS_NAME_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const COMPANY_NAME_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const NIF_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const CITY_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const STREET_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const MAIL_BOX_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  const SERVICES_TYPES_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const OTHER_SERVICES_TYPES_ERROR_MESSAGES = {
    required: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.REQUIRED',
    }),
  };

  const ADDITIONAL_INFORMATION_ERROR_MESSAGES = {
    maxCharacters: intl.formatMessage({
      id: 'YUP.VALIDATION.COMMUN.MAX_CHARACTERS',
    }),
  };

  // ############### Request Submitter ###############

  const requestSubmitterFullNameValidationSchema = () =>
    Yup.object().shape({
      requestSubmitterFullName: Yup.string()
        .max(255, FULL_NAME_ERROR_MESSAGES.maxCharacters)
        .required(FULL_NAME_ERROR_MESSAGES.required)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const requestSubmitterEmailValidationSchema = () =>
    Yup.object().shape({
      requestSubmitterEmail: Yup.string()
        .email(EMAIL_ERROR_MESSAGES.email)
        .nullable()
        .required(EMAIL_ERROR_MESSAGES.required),
    });

  const requestSubmitterPhoneNumberValidationSchema = () =>
    Yup.object().shape({
      requestSubmitterPhoneNumber: Yup.string()
        .required(PHONE_NUMBER_ERROR_MESSAGES.required)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const requestSubmitterCellPhoneValidationSchema = () =>
    Yup.object().shape({
      requestSubmitterCellPhone: Yup.string()
        .required(PHONE_NUMBER_ERROR_MESSAGES.required)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const requestSubmitterPositionWithinCompanyValidationSchema = () =>
    Yup.object().shape({
      requestSubmitterPositionWithinTheCompany: Yup.string()
        .max(255, POSITION_WITHIN_COMPANY_ERROR_MESSAGES.maxCharacters)
        .required(POSITION_WITHIN_COMPANY_ERROR_MESSAGES.required)
        .test(
          'no-leading-trailing-spaces',
          COMMON_MESSAGE.leadingSpacesStartAndEnd,
          (value) => {
            return (
              value === undefined ||
              (value.trimStart() === value && value.trimEnd() === value)
            );
          },
        )
        .nullable(),
    });

  const requestSubmitterCountryValidationSchema = () =>
    Yup.object().shape({
      requestSubmitterCountry: Yup.object()
        .required(COUNTRY_ERROR_MESSAGES.required)
        .nullable(),
    });

  // ############### Ship Information ###############

  const shipIMOValidationSchema = () =>
    Yup.object().shape({
      shipIMO: Yup.string()
        .min(7, IMO_ERROR_MESSAGES.minCharacters)
        .max(7, IMO_ERROR_MESSAGES.maxCharacters)
        .required(IMO_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipNameValidationSchema = () =>
    Yup.object().shape({
      shipName: Yup.string()
        .max(255, SHIP_NAME_ERROR_MESSAGES.maxCharacters)
        .required(SHIP_NAME_ERROR_MESSAGES.required)
        .test(
          'no-leading-trailing-spaces',
          COMMON_MESSAGE.leadingSpacesStartAndEnd,
          (value) => {
            return (
              value === undefined ||
              (value.trimStart() === value && value.trimEnd() === value)
            );
          },
        )
        .nullable(),
    });

  const shipCountryValidationSchema = () =>
    Yup.object().shape({
      shipCountry: Yup.object()
        .required(COUNTRY_ERROR_MESSAGES.required)
        .nullable(),
    });

  const registrationPortValidationSchema = () =>
    Yup.object().shape({
      shipRegistrationPort: Yup.string()
        .max(255, REGISTRATION_PORT_ERROR_MESSAGES.maxCharacters)
        .required(REGISTRATION_PORT_ERROR_MESSAGES.required)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const shipMaximumDisplacementValidationSchema = () =>
    Yup.object().shape({
      shipMaximumDisplacement: Yup.string()
        .max(255, MAXIMUM_DISPLACEMENT_ERROR_MESSAGES.maxCharacters)
        .required(MAXIMUM_DISPLACEMENT_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipLightDisplacementValidationSchema = () =>
    Yup.object().shape({
      shipLightDisplacement: Yup.string()
        .max(255, LIGHT_DISPLACEMENT_ERROR_MESSAGES.maxCharacters)
        .required(LIGHT_DISPLACEMENT_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipLengthValidationSchema = () =>
    Yup.object().shape({
      shipLength: Yup.number()
        .required(SHIP_LENGTH_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipOverallLengthValidationSchema = () =>
    Yup.object().shape({
      shipOverallLength: Yup.number()
        .required(SHIP_OVERALL_LENGTH_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipWidthValidationSchema = () =>
    Yup.object().shape({
      shipWidth: Yup.number()
        .required(SHIP_WIDTH_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipYearValidationSchema = () =>
    Yup.object().shape({
      shipYear: Yup.string()
        .matches(/^\d{4}$/, SHIP_YEAR_ERROR_MESSAGES.exactDigits)
        .required(SHIP_YEAR_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipDraftValidationSchema = () =>
    Yup.object().shape({
      shipDraft: Yup.string()
        .required(SHIP_DRAFT_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipDrafDepthValidationSchema = () =>
    Yup.object().shape({
      shipDepth: Yup.string()
        .required(SHIP_DEPTH_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipPropulsionSystemTypeIdValidationSchema = () =>
    Yup.object().shape({
      shipPropulsionSystemTypeId: Yup.object()
        .required(SHIP_PROPULSION_SYSTEM_TYPE_ID.required)
        .nullable(),
    });

  const shipClassAndClassifyingValidationSchema = () =>
    Yup.object().shape({
      shipClassAndClassificationSocity: Yup.string()
        .max(255, CLASS_AND_CLASSIFYING_ERROR_MESSAGES.maxCharacters)
        .test(
          'no-leading-trailing-spaces',
          COMMON_MESSAGE.leadingSpacesStartAndEnd,
          (value) => {
            return (
              value === undefined ||
              (value.trimStart() === value && value.trimEnd() === value)
            );
          },
        )
        .nullable(),
    });

  const shipLightShipDraftValidationSchema = () =>
    Yup.object().shape({
      shipLightShipDraft: Yup.string()
        .required(SHIP_LIGHT_SHIP_DRAFT_ERROR_MESSAGES.required)
        .nullable(),
    });

  const shipOnBoardElectricalPowerSystemVoltageValidationSchema = () =>
    Yup.object().shape({
      shipOnBoardElectricalPowerSystemVoltage: Yup.string()
        .required(
          SHIP_ON_BOARD_ELECTRICAL_POWER_VOLTAGE_ERROR_MESSAGES.required,
        )
        .test(
          'no-leading-trailing-spaces',
          COMMON_MESSAGE.leadingSpacesStartAndEnd,
          (value) => {
            return (
              value === undefined ||
              (value.trimStart() === value && value.trimEnd() === value)
            );
          },
        )
        .nullable(),
    });

  const shipOnBoardElectricalPowerSystemFrequencyValidationSchema = () =>
    Yup.object().shape({
      shipOnBoardElectricalPowerSystemFrequency: Yup.string()
        .required(
          SHIP_ON_BOARD_ELECTRICAL_POWER_FREQUENCY_ERROR_MESSAGES.required,
        )
        .nullable(),
    });

  const shipBuildingYardsNameValidationSchema = () =>
    Yup.object().shape({
      shipBuildingYardsName: Yup.string()
        .max(255, SHIP_BUILDING_YARDS_NAME_ERROR_MESSAGES.maxCharacters)
        .required(SHIP_BUILDING_YARDS_NAME_ERROR_MESSAGES.required)
        .test(
          'no-leading-trailing-spaces',
          COMMON_MESSAGE.leadingSpacesStartAndEnd,
          (value) => {
            return (
              value === undefined ||
              (value.trimStart() === value && value.trimEnd() === value)
            );
          },
        )
        .nullable(),
    });

  const shipBuildingYardsCountryValidationSchema = () =>
    Yup.object().shape({
      shipBuildingYardsCountry: Yup.object()
        .required(COUNTRY_ERROR_MESSAGES.required)
        .nullable(),
    });

  // ############### Shipowner Information ###############

  const companyNameValidationSchema = () =>
    Yup.object().shape({
      companyName: Yup.string()
        .max(255, COMPANY_NAME_ERROR_MESSAGES.maxCharacters)
        .required(COMPANY_NAME_ERROR_MESSAGES.required)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const companyEmailValidationSchema = () =>
    Yup.object().shape({
      companyEmail: Yup.string()
        .email(EMAIL_ERROR_MESSAGES.email)
        .nullable()
        .required(EMAIL_ERROR_MESSAGES.required),
    });

  const companyPhoneNumberValidationSchema = () =>
    Yup.object().shape({
      companyPhoneNumber: Yup.string()
        .required(PHONE_NUMBER_ERROR_MESSAGES.required)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const companyCellPhoneValidationSchema = () =>
    Yup.object().shape({
      companyCellPhone: Yup.string()
        .required(PHONE_NUMBER_ERROR_MESSAGES.required)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const companyNifValidationSchema = () =>
    Yup.object().shape({
      companyNif: Yup.string().required(NIF_ERROR_MESSAGES.required).nullable(),
    });

  const companyCountryValidationSchema = () =>
    Yup.object().shape({
      companyCountry: Yup.object()
        .required(COUNTRY_ERROR_MESSAGES.required)
        .nullable(),
    });

  const companyCityValidationSchema = () =>
    Yup.object().shape({
      companyCity: Yup.string()
        .max(255, CITY_ERROR_MESSAGES.maxCharacters)
        .required(CITY_ERROR_MESSAGES.required)
        .test(
          'no-leading-trailing-spaces',
          COMMON_MESSAGE.leadingSpacesStartAndEnd,
          (value) => {
            return (
              value === undefined ||
              (value.trimStart() === value && value.trimEnd() === value)
            );
          },
        )
        .nullable(),
    });

  const companyStreetValidationSchema = () =>
    Yup.object().shape({
      companyStreet: Yup.string()
        .max(255, STREET_ERROR_MESSAGES.maxCharacters)
        .required(STREET_ERROR_MESSAGES.required)
        .test(
          'no-leading-trailing-spaces',
          COMMON_MESSAGE.leadingSpacesStartAndEnd,
          (value) => {
            return (
              value === undefined ||
              (value.trimStart() === value && value.trimEnd() === value)
            );
          },
        )
        .nullable(),
    });

  const companyMailBoxValidationSchema = () =>
    Yup.object().shape({
      companyMailBox: Yup.string()
        .max(255, MAIL_BOX_ERROR_MESSAGES.maxCharacters)
        .required(MAIL_BOX_ERROR_MESSAGES.required)
        .test(
          'no-leading-trailing-spaces',
          COMMON_MESSAGE.leadingSpacesStartAndEnd,
          (value) => {
            return (
              value === undefined ||
              (value.trimStart() === value && value.trimEnd() === value)
            );
          },
        )
        .nullable(),
    });

  const shipTypeAndOtherShipTypeValidationSchema = () =>
    Yup.object().shape({
      shipTypeID: Yup.string().required(SHIP_TYPE_ERROR_MESSAGES.required),
      otherShipType: Yup.string().when('shipTypeID', {
        is: (shipTypeValue) => shipTypeValue === 'OTHER',
        then: Yup.string()
          .required(OTHER_SHIP_TYPE_ERROR_MESSAGES.required)
          .test(
            'no-leading-spaces',
            COMMON_MESSAGE.leadingSpacesStart,
            (value) => {
              return value === undefined || value.trimStart() === value;
            },
          )
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
    });

  const servicesTypesValidationSchema = () =>
    Yup.object().shape({
      servicesTypes: Yup.array()
        .required(SERVICES_TYPES_ERROR_MESSAGES.required)
        .min(1, SERVICES_TYPES_ERROR_MESSAGES.required)
        .nullable(),
    });

  const servicesTypesAndOtherServicesTypesValidationSchema = () =>
    Yup.object().shape({
      servicesTypes: Yup.array()
        .required(SERVICES_TYPES_ERROR_MESSAGES.required)
        .nullable(),
      serviceAdditionalDescriptionsOTHER: Yup.string().when('servicesTypes', {
        is: (serviceTypeValue) => serviceTypeValue.includes('OTHER'),
        then: Yup.string()
          .required(OTHER_SERVICES_TYPES_ERROR_MESSAGES.required)
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
    });

  const additionalEletricityDescriptionValidationSchema = () =>
    Yup.object().shape({
      serviceAdditionalDescriptionsELECTRICITY: Yup.string()
        .max(255, COMMON_MESSAGE.maxCharacters)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const additionalCarpentryDescriptionValidationSchema = () =>
    Yup.object().shape({
      serviceAdditionalDescriptionsCARPENTRY: Yup.string()
        .max(255, COMMON_MESSAGE.maxCharacters)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const additionalBoilermakingDescriptionValidationSchema = () =>
    Yup.object().shape({
      serviceAdditionalDescriptionsBOILERMAKING: Yup.string()
        .max(255, COMMON_MESSAGE.maxCharacters)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const additionalMechanicsDescriptionValidationSchema = () =>
    Yup.object().shape({
      serviceAdditionalDescriptionsMECHANICS: Yup.string()
        .max(255, COMMON_MESSAGE.maxCharacters)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const additionalHullTreatmentDescriptionValidationSchema = () =>
    Yup.object().shape({
      serviceAdditionalDescriptionsHULL_TREATMENT: Yup.string()
        .max(255, COMMON_MESSAGE.maxCharacters)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const additionaNonDestructiveTestingDescriptionValidationSchema = () =>
    Yup.object().shape({
      serviceAdditionalDescriptionsNON_DESTRUCTIVE_TESTING: Yup.string()
        .max(255, COMMON_MESSAGE.maxCharacters)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const additionalMechanicalLocksmithingDescriptionValidationSchema = () =>
    Yup.object().shape({
      serviceAdditionalDescriptionsMECHANICAL_LOCKSMITHING: Yup.string()
        .max(255, COMMON_MESSAGE.maxCharacters)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const otherServiceValidationSchema = () =>
    Yup.object().shape({
      serviceAdditionalDescriptionsOTHER: Yup.string()
        .max(255, COMMON_MESSAGE.maxCharacters)
        .test(
          'no-leading-spaces',
          COMMON_MESSAGE.leadingSpacesStart,
          (value) => {
            return value === undefined || value.trimStart() === value;
          },
        )
        .nullable(),
    });

  const phoneNumberValidationSchema = () =>
    Yup.object().shape({
      requestSubmitterCellPhone: Yup.string()
        .required(PHONE_NUMBER_ERROR_MESSAGES.required)
        .nullable(),
    });

  const additionalInformationValidationSchema = () =>
    Yup.object().shape({
      additionalInformation: Yup.string()
        .max(255, ADDITIONAL_INFORMATION_ERROR_MESSAGES.maxCharacters)
        .test(
          'no-leading-trailing-spaces',
          COMMON_MESSAGE.leadingSpacesStartAndEnd,
          (value) => {
            return (
              value === undefined ||
              (value.trimStart() === value && value.trimEnd() === value)
            );
          },
        )
        .nullable(),
    });

  return {
    shipIMOValidationSchema,
    requestSubmitterEmailValidationSchema,
    companyEmailValidationSchema,
    companyNameValidationSchema,
    shipNameValidationSchema,
    shipLengthValidationSchema,
    shipWidthValidationSchema,
    shipOverallLengthValidationSchema,
    shipYearValidationSchema,
    shipTypeAndOtherShipTypeValidationSchema,
    servicesTypesValidationSchema,
    shipClassAndClassifyingValidationSchema,
    servicesTypesAndOtherServicesTypesValidationSchema,
    requestSubmitterPhoneNumberValidationSchema,
    companyPhoneNumberValidationSchema,
    companyCellPhoneValidationSchema,
    requestSubmitterCellPhoneValidationSchema,
    additionalEletricityDescriptionValidationSchema,
    additionalCarpentryDescriptionValidationSchema,
    additionalBoilermakingDescriptionValidationSchema,
    additionalMechanicsDescriptionValidationSchema,
    additionalHullTreatmentDescriptionValidationSchema,
    additionaNonDestructiveTestingDescriptionValidationSchema,
    additionalMechanicalLocksmithingDescriptionValidationSchema,
    otherServiceValidationSchema,
    phoneNumberValidationSchema,
    requestSubmitterPositionWithinCompanyValidationSchema,
    registrationPortValidationSchema,
    requestSubmitterCountryValidationSchema,
    companyCountryValidationSchema,
    shipCountryValidationSchema,
    shipBuildingYardsCountryValidationSchema,
    shipPropulsionSystemTypeIdValidationSchema,
    requestSubmitterFullNameValidationSchema,
    companyNifValidationSchema,
    companyCityValidationSchema,
    companyStreetValidationSchema,
    companyMailBoxValidationSchema,
    shipMaximumDisplacementValidationSchema,
    shipLightDisplacementValidationSchema,
    shipDraftValidationSchema,
    shipDrafDepthValidationSchema,
    shipLightShipDraftValidationSchema,
    shipOnBoardElectricalPowerSystemVoltageValidationSchema,
    shipOnBoardElectricalPowerSystemFrequencyValidationSchema,
    shipBuildingYardsNameValidationSchema,
    additionalInformationValidationSchema,
  };
};

export default useSchemaValidation;
