import NotFound from './components/NotFound';
import Chat from './pages/Chat';
import FormRequest from './pages/FormRequest';
import SuccessSubmit from './pages/SuccessSubmit';
import ViewRequest from './pages/ViewRequest';

const routes = [
  {
    path: '/solicitacoes/:id',
    element: <ViewRequest />,
  },
  {
    path: '/',
    element: <FormRequest />,
  },
  {
    path: '/sucesso',
    element: <SuccessSubmit />,
  },
  {
    path: '/conversas/:id',
    element: <Chat />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
