import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import ChatMessage from '../../components/ChatMessage';
import TextInput from '../../components/Inputs/TextInput';
import SectionHeader from '../../components/SectionHeader';

const Chat = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(document.location.search);
  const hash = searchParams.get('hash');
  const [message, setMessage] = useState([]);
  const fullUrl = window.location.href;
  const [workorderId, setWorkorderId] = useState(null);
  const [messageToSend, setMessageToSend] = useState('');
  const WS_URL = `${process.env.REACT_APP_CHAT_SOCKET_API}?hash=${hash}`;

  useEffect(() => {
    const parts = fullUrl.split('/conversas/');
    setWorkorderId(parts[1].split('?')[0]);
  }, []);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    WS_URL,
    {
      share: false,
      shouldReconnect: () => true,
      onError: () => navigate(`/solicitacoes/${workorderId}`),
    },
  );

  const handleSendMessageSocket = () => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        channel: 'send_message',
        data: JSON.stringify({
          message: messageToSend,
          hash,
        }),
      });
      setMessageToSend('');
    }
  };

  useEffect(() => {
    if (lastJsonMessage) {
      setMessage([...message, ...lastJsonMessage]);
    }
  }, [lastJsonMessage]);

  const removeSpaces = (str) => {
    // Remove spaces from the string
    return str.replace(/\s/g, '');
  };

  const isSendButtonEnabled = () => {
    return messageToSend && removeSpaces(messageToSend);
  };

  const renderContent = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '75vh',
          bgcolor: '#fff',
          width: '100%',
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              p: 2,
              bgColor: 'green',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '0em',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0)',
              },
            }}
          >
            <ChatMessage data={message} isLoading={!lastJsonMessage} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              bgcolor: '#fff',
              p: 2,
            }}
          >
            <TextInput
              value={messageToSend}
              onChange={(element) => setMessageToSend(element.target.value)}
              name="chat"
              label="Envie uma mensagem"
              multiline
            />
            <IconButton
              disabled={!isSendButtonEnabled()}
              onClick={() => handleSendMessageSocket()}
            >
              <SendIcon
                sx={{
                  color: isSendButtonEnabled()
                    ? theme.palette.primary.main
                    : theme.palette.grey[400],
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Conversa | Cabnave</title>
      </Helmet>
      <SectionHeader title="Conversa" description="Minha Conversa" isPrimary />
      {renderContent()}
    </>
  );
};

export default Chat;
