import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import rawAxios from 'axios';

import axios from '../lib/axios';
import addCurrentLocalTime from '../utils/addCurrentLocalTime';
import { DATE_FORMATS } from '../utils/constants';
import formatDateToLocal from '../utils/formatDate';

const initialState = {
  request: null,
  isLoading: false,
  error: null,
  success: null,
  files: null,
  isLoadingFiles: false,
  filesError: null,
};

const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    _createRequest: (state) => {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    _createRequestSuccess: (state) => {
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    },
    _createRequestFailure: (state) => {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    },
    _clearSuccess: () => {
      return {
        error: null,
        success: null,
      };
    },
    _getById: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    _getByIdSuccess: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        request: payload,
      };
    },
    _getByIdFailure: (state) => {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    },
    _getFiles: (state) => {
      return {
        ...state,
        isLoadingFiles: true,
      };
    },
    _getFilesSuccess: (state, { payload }) => {
      return {
        ...state,
        isLoadingFiles: false,
        filesError: null,
        files: payload,
      };
    },
    _getFilesFailure: (state, { payload }) => {
      return {
        ...state,
        isLoadingFiles: false,
        filesError: payload,
      };
    },
  },
});

const {
  _getById,
  _getByIdSuccess,
  _getByIdFailure,
  _clearSuccess,
  _createRequest,
  _createRequestSuccess,
  _createRequestFailure,
  _getFiles,
  _getFilesSuccess,
  _getFilesFailure,
} = slice.actions;

export default slice.reducer;

export const fetchById = createAsyncThunk(
  'request/fetch_by_id',
  async (id, { dispatch }) => {
    try {
      dispatch(_getById());
      const { data } = await axios.get(`/workorders/${id}`);

      const orderedRequest = {
        ...data,
        entrance_date: data.entrance_date
          ? formatDateToLocal(data.entrance_date, DATE_FORMATS.extended_date)
          : null,
        exit_date: data.exit_date
          ? formatDateToLocal(data.exit_date, DATE_FORMATS.extended_date)
          : null,
        ordered_request_ship_information: {
          ordered_request_shipowner: {
            shipowner_name: data.ship.ship_owner.name,
            shipowner_fiscal_number: data.ship.ship_owner.fiscal_number,
            shipowner_email: data.ship.ship_owner.email,
            shipowner_phone_number: data.ship.ship_owner.phone_number,
            shipowner_cell_phone: data.ship.ship_owner.cell_phone,
            shipowner_street: data.ship.ship_owner.street,
            shipowner_city: data.ship.ship_owner.city,
            shipowner_country: data.ship.ship_owner.country,
            shipowner_mailbox: data.ship.ship_owner.mailbox,
          },
          ship_information_imo: data.ship.imo,
          ship_information_name: data.ship.name,
          ship_information_country: data.ship.country_id,
          ship_information_registration_port: data.ship.registration_port,
          ship_information_building_yards_name:
            data.ship.ship_building_yards_name,
          ship_information_building_yards_country:
            data.ship.ship_building_yards_country,
          ship_information_year: data.ship.year,
          ship_information_ship_type_name: data.ship.ship_type_name
            ? data.ship.ship_type_name
            : data.ship.ship_type_id,
          ship_information_ship_class_and_classification_society:
            data.ship.class_and_classification_society,
          ship_information_length: data.ship.length,
          ship_information_overall_length: data.ship.overall_length,
          ship_information_width: data.ship.width,
          ship_information_light_displacement: data.ship.light_displacement,
          ship_information_maximum_displacement: data.ship.maximum_displacement,
          ship_information_draft: data.ship.draft,
          ship_information_light_ship_draft: data.ship.light_ship_draft,
          ship_information_depth: data.ship.depth,
          ship_information_propulsion_system_type_id:
            data.ship.propulsion_system_type_id,
          ship_information_onboard_electrical_power_system_voltage:
            data.ship.onboard_electrical_power_system_voltage,
          ship_information_onboard_electrical_power_system_frequency:
            data.ship.onboard_electrical_power_system_frequency,
        },
        ordered_request_submitter: {
          full_name: data.work_order_submitter.full_name,
          email: data.work_order_submitter.email,
          phone_number: data.work_order_submitter.phone_number,
          cell_phone: data.work_order_submitter.cell_phone,
          position_within_the_company:
            data.work_order_submitter.position_within_the_company,
          country: data.work_order_submitter.country,
        },
      };

      dispatch(_getByIdSuccess(orderedRequest));
    } catch (error) {
      dispatch(_getByIdFailure(error.message));
    }
  },
);

export const clearSuccess = createAsyncThunk(
  'request/clear_success',
  async (id, { dispatch }) => {
    dispatch(_clearSuccess());
  },
);

export const create = createAsyncThunk(
  'request/create_request',
  async (data, { dispatch }) => {
    try {
      dispatch(_createRequest());

      const formatServices = (inputObject) => {
        const services = [];

        if (
          inputObject.servicesTypes &&
          Array.isArray(inputObject.servicesTypes)
        ) {
          inputObject.servicesTypes.forEach((serviceType) => {
            const service = {
              id: serviceType,
              description:
                inputObject[`serviceAdditionalDescriptions${serviceType}`] ||
                undefined,
            };
            services.push(service);
          });
        }
        return services;
      };

      const body = {
        ship: {
          imo: data.shipIMO.toString(),
          name: data.shipName,
          ship_type_id: data.shipTypeID,
          ship_type_name:
            data.shipTypeID === 'OTHER' ? data.otherShipType : undefined,
          country_id: data.shipCountry.isoCode,
          length: data.shipLength,
          width: data.shipWidth,
          year: data.shipYear,
          registration_port: data.shipRegistrationPort,
          overall_length: data.shipOverallLength,
          draft: data.shipDraft,
          depth: data.shipDepth,
          light_ship_draft: data.shipLightShipDraft,
          ship_building_yards_name: data.shipBuildingYardsName,
          ship_building_yards_country: data.shipBuildingYardsCountry.isoCode,
          onboard_electrical_power_system_voltage:
            data.shipOnBoardElectricalPowerSystemVoltage,
          onboard_electrical_power_system_frequency:
            data.shipOnBoardElectricalPowerSystemFrequency,
          propulsion_system_type_id: data.shipPropulsionSystemTypeId.id,
          class_and_classification_society:
            data.shipClassAndClassificationSocity
              ? data.shipClassAndClassificationSocity
              : undefined,
          light_displacement: data.shipLightDisplacement,
          maximum_displacement: data.shipMaximumDisplacement,
        },
        ship_owner: {
          name: data.companyName,
          email: data.companyEmail,
          phone_number: data.companyPhoneNumber,
          cell_phone: data.companyCellPhone,
          street: data.companyStreet,
          city: data.companyCity,
          mailbox: data.companyMailBox,
          country: data.companyCountry.isoCode,
          fiscal_number: data.companyNif.toString(),
        },
        work_order_submitter: {
          full_name: data.requestSubmitterFullName,
          email: data.requestSubmitterEmail,
          phone_number: data.requestSubmitterPhoneNumber,
          cell_phone: data.requestSubmitterCellPhone,
          country: data.requestSubmitterCountry.isoCode,
          position_within_the_company:
            data.requestSubmitterPositionWithinTheCompany,
        },
        entrance_date: data.entryDate
          ? addCurrentLocalTime(data.entryDate)
          : undefined,
        exit_date: data.exitDate
          ? addCurrentLocalTime(data.exitDate)
          : undefined,
        services: formatServices(data),
        observation:
          // eslint-disable-next-line no-nested-ternary
          data.additionalInformation
            ? data.additionalInformation !== ''
              ? data.additionalInformation
              : undefined
            : undefined,
      };

      const workorderResponse = await axios.post('/workorders', body);
      const { id } = workorderResponse.data;

      if (data.files) {
        const filesArray = Array.from(data.files);

        const promises = filesArray.map(async (file) => {
          const presignedURLResponse = await axios.get(
            `/workorders/${id}/attachments/${encodeURIComponent(
              file.name,
            )}/presigned_url`,
          );
          const { url } = presignedURLResponse.data;

          await rawAxios.put(url, file);

          await axios.put(`workorders/${id}/attachments`, {
            filename: encodeURIComponent(file.name),
          });
        });

        await Promise.all(promises);
      }

      dispatch(_createRequestSuccess());
    } catch (error) {
      dispatch(_createRequestFailure());
    }
  },
);

export const fetchFiles = createAsyncThunk(
  'fetch/files',
  async (id, { dispatch }) => {
    try {
      dispatch(_getFiles());

      const response = await axios.get(`/workorders/${id}/attachments`);

      dispatch(_getFilesSuccess(response.data));
    } catch (error) {
      dispatch(_getFilesFailure(error.message));
    }
  },
);
