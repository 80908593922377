import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import './style.css';

const NumericTextField = ({
  id,
  label,
  error,
  helperText,
  onChange,
  onBlur,
  value,
  name,
}) => {
  const handlePaste = (e) => {
    // [ˆe]|^[0-9]*$ - regex to match only numbers
    if (!e.target.value.match(/[ˆe]|^[0-9]*$/)) {
      e.preventDefault();
    }
  };

  return (
    <TextField
      id={id}
      fullWidth
      error={error}
      label={label}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      type="number"
      InputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        type: 'number',
      }}
      onKeyDown={(e) => {
        // to prevent the user to type any character different from a number
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          e.preventDefault();
        }
      }}
      onPasteCapture={handlePaste}
      onPaste={handlePaste}
    />
  );
};

NumericTextField.defaultProps = {
  id: null,
  error: null,
  helperText: null,
};

NumericTextField.propTypes = {
  id: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default NumericTextField;
