import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  Typography,
  TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ContentCard from '../ContentCard';

const FileList = ({ files }) => {
  const intl = useIntl();
  const handleOpenFile = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const hasData = () => {
    return files.length > 0;
  };

  const noBorder = { '& td': { border: 0 } };

  const content = () => {
    return hasData() ? (
      files.map((file, index) => {
        return (
          <TableRow
            key={document.document_id}
            sx={{
              ...(index === files.length - 1 ? noBorder : {}),
              cursor: 'pointer',
            }}
            onClick={() => {
              handleOpenFile(file.url);
            }}
          >
            <TableCell sx={{ width: '100%' }}>
              <Typography variant="body3">{file.filename}</Typography>
            </TableCell>
            <TableCell sx={{ width: '100%' }}>
              <LinkOutlinedIcon />
            </TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableCell sx={{ border: 0 }}>
        <Typography variant="body3">
          {intl.formatMessage({
            id: 'NO_DATA',
          })}
        </Typography>
      </TableCell>
    );
  };

  return (
    <ContentCard>
      <TableContainer>
        <Table>
          <TableBody>{content()}</TableBody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};

FileList.propTypes = {
  files: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default FileList;
