import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useRoutes } from 'react-router-dom';

import Footer from './components/Footer';
import Header from './components/Header';
import routes from './routes';
import messagesEN from './translations/en.json';
import messagesPT from './translations/pt.json';
import {
  getLanguageFromLocalStorage,
  setLanguageOnLocalStorage,
} from './utils/languagesFunctions';

const messages = {
  pt: messagesPT,
  en: messagesEN,
};

export default function App() {
  const content = useRoutes(routes);
  const [language, setLanguage] = useState(
    getLanguageFromLocalStorage() || null,
  );

  useEffect(() => {
    if (language) {
      setLanguageOnLocalStorage(language);
    }
  }, [language]);

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
          backgroundColor: 'background.main',
        }}
      >
        <Box>
          <Header language={language} changeLanguageOnLocale={setLanguage} />
          <Container maxWidth="xl">{content}</Container>
        </Box>
        <Footer />
      </Box>
    </IntlProvider>
  );
}
