const setLanguageOnLocalStorage = (lang) => localStorage.setItem('lang', lang);

const getLanguageFromLocalStorage = () => {
  const storedLang = localStorage.getItem('lang');
  return storedLang || 'pt';
};
module.exports = {
  setLanguageOnLocalStorage,
  getLanguageFromLocalStorage,
};
