import * as Yup from 'yup';

const getCombinedValidationSchema = (schemas) => {
  const validSchemas = schemas.filter((schema) => schema instanceof Yup.object);

  if (validSchemas.length === 0) {
    throw new Error('No valid schemas found to combine.');
  }

  return validSchemas.reduce(
    (combinedSchema, schema) => combinedSchema.concat(schema),
    Yup.object(),
  );
};

export default getCombinedValidationSchema;
