const SUPPORTED_LANGUAGES = {
  pt: {
    iso: 'pt',
    value: 'pt',
    label: 'Português',
  },
  en: {
    iso: 'us',
    value: 'en',
    label: 'English',
  },
};

const DATE_FORMATS = {
  basic_date: 'yyyyMMdd',
  extended_date: 'yyyy-MM-dd',
  basic_week_date: 'yyyyWwwD',
  extended_week_date: 'yyyy-Www-D',
  basic_ordinal_date: 'yyyyDDD',
  extended_ordinal_date: 'yyyy-DDD',
  basic_time: 'hhmmss',
  extended_time: 'hh:mm:ss',
  time_with_decimal_fraction: 'hhmmss.sss',
  extended_time_with_decimal_fraction: 'hh:mm:ss.sss',
  basic_date_time: 'yyyyMMddThhmmss',
  extended_date_time: 'yyyy-MM-ddThh:mm:ss',
  date_time_with_time_zone: 'yyyy-MM-ddThh:mm:ss±hh:mm',
  extended_date_time_with_fractional_seconds_and_time_zone:
    'yyyy-MM-ddThh:mm:ss.sss±hh:mm',
  date_time_with_fractional_seconds_and_offset: "yyyy-MM-dd'T'HH:mm:ss.SSSxx'",
};

module.exports = {
  SUPPORTED_LANGUAGES,
  DATE_FORMATS,
};
