import { combineReducers } from '@reduxjs/toolkit';

import chat from '../slices/chat';
import countries from '../slices/countries';
import propulsionSystemTypes from '../slices/propulsionSystemTypes';
import request from '../slices/request';
import typesOfServices from '../slices/typesOfServices';
import typesOfShips from '../slices/typesOfShips';

const appReducer = combineReducers({
  request,
  countries,
  typesOfServices,
  typesOfShips,
  chat,
  propulsionSystemTypes,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
