import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import ChipButton from '../../Button/ChipButton';
import ContentCard from '../../ContentCard';

const StatusInformation = ({ request }) => {
  const noBorder = { '& td': { border: 0 } };

  const getStatusButtonLabel = (data) => {
    if (data === 'accepted') {
      return <ChipButton label="Aprovado" type="approved" />;
    }

    return <ChipButton label="Pendente" type="pending" />;
  };

  return (
    <ContentCard>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body2">Estado de Entrada</Typography>
              </TableCell>
              <TableCell>
                {getStatusButtonLabel(request.entrance_status)}
              </TableCell>
            </TableRow>
            <TableRow sx={noBorder}>
              <TableCell>
                <Typography variant="body2">Estado de Saída</Typography>
              </TableCell>
              <TableCell>{getStatusButtonLabel(request.exit_status)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};

StatusInformation.propTypes = {
  request: PropTypes.shape({
    entrance_status: PropTypes.string.isRequired,
    exit_status: PropTypes.string.isRequired,
  }).isRequired,
};

export default StatusInformation;
