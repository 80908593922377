import { Box, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import theme from '../../../theme';
import Checkbox from '../../Inputs/Checkbox';
import TextInput from '../../Inputs/TextInput';

const ServiceInformations = ({
  values,
  errors,
  touched,
  handleChange,
  typesOfServices,
  setFieldValue,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'request_form.services',
          })}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {errors.servicesTypes && touched.servicesTypes && (
          <Typography sx={{ color: theme.palette.error.main }}>
            {errors.servicesTypes}
          </Typography>
        )}
        {typesOfServices.map((service) => (
          <Box key={service.id}>
            <Checkbox
              name="servicesTypes"
              value={values.servicesTypes}
              error={Boolean(touched.servicesTypes && errors.servicesTypes)}
              touched={Boolean(touched.servicesTypes)}
              helperText={touched.servicesTypes && errors.servicesTypes}
              onChange={handleChange}
              option={service.id}
              key={service.id}
            />
            {values.servicesTypes &&
              values.servicesTypes.includes(service.id) && (
                <TextInput
                  key={`serviceAdditionalDescriptions${service.id}`}
                  name={`serviceAdditionalDescriptions${service.id}`}
                  error={Boolean(
                    touched[`serviceAdditionalDescriptions${service.id}`] &&
                      errors[`serviceAdditionalDescriptions${service.id}`],
                  )}
                  label={intl.formatMessage({
                    id:
                      service.id === 'OTHER'
                        ? 'OTHER'
                        : `REQUEST_FORM.SERVICES.ADDITIONAL_DESCRIPTION`,
                  })}
                  helperText={
                    touched[`serviceAdditionalDescriptions${service.id}`] &&
                    errors[`serviceAdditionalDescriptions${service.id}`]
                  }
                  onChange={handleChange}
                />
              )}
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            name="entryDate"
            format={intl.formatMessage({
              id: 'REQUEST_FORM.SERVICE_INFORMATION.DATE.PLACEHOLDER',
            })}
            fullWidth
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SERVICE_INFORMATION.ESTIMATED_ENTRY_DATE',
            })}
            onChange={(newValue) => {
              setFieldValue('entryDate', newValue);
            }}
          />
          <DateField
            name="exitDate"
            format={intl.formatMessage({
              id: 'REQUEST_FORM.SERVICE_INFORMATION.DATE.PLACEHOLDER',
            })}
            fullWidth
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SERVICE_INFORMATION.ESTIMATED_DEPARTURE_DATE',
            })}
            onChange={(newValue) => {
              setFieldValue('exitDate', newValue);
            }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

ServiceInformations.propTypes = {
  values: PropTypes.shape({
    servicesTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  errors: PropTypes.shape({
    servicesTypes: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    servicesTypes: PropTypes.bool,
  }).isRequired,
  typesOfServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default ServiceInformations;
