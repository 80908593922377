import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const RadioGroupInput = ({
  name,
  value,
  error,
  touched,
  options,
  label,
  onChange,
}) => {
  const intl = useIntl();

  return (
    <FormControl component="fieldset" error={Boolean(touched && error)}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        id={name}
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
        row
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio />}
            label={intl.formatMessage({
              id: `REQUEST_FROM.${option.id}`,
            })}
          />
        ))}
      </RadioGroup>

      <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
  );
};

RadioGroupInput.defaultProps = {
  touched: false,
  error: false,
  value: null,
};

RadioGroupInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  options: PropTypes.arrayOf(undefined).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioGroupInput;
