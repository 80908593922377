import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../lib/axios';

const initialState = {
  countries: null,
  isLoading: false,
  error: null,
  success: null,
};

const slice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    _get: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    _getSuccess: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        countries: payload,
      };
    },
    _getFailure: (state) => {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    },
  },
});

const { _get, _getSuccess, _getFailure } = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'countries/fetch',
  async (_, { dispatch }) => {
    try {
      dispatch(_get());
      const { data } = await axios.get('/countries');

      const orderedData = data.sort((a, b) => a.name.localeCompare(b.name));

      const newData = orderedData.map((item) => ({
        name: item.name,
        isoCode: item.iso_code,
      }));

      dispatch(_getSuccess(newData));
    } catch (error) {
      dispatch(_getFailure());
    }
  },
);
