import { Box, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../components/Button/CustomButton';
import ContentCard from '../../components/ContentCard';
import CustomSnackBar from '../../components/CustomSnackBar';
import Error from '../../components/Error';
import AdditionalInformation from '../../components/Forms/AdditionalInformation';
import RequestSubmitterInformation from '../../components/Forms/RequestSubmitterInformation';
import ServiceInformations from '../../components/Forms/ServiceInformations';
import ShipInformation from '../../components/Forms/ShipInformation';
import ShipOwnerInformation from '../../components/Forms/ShipOwnerInformation';
import UploadDocuments from '../../components/Forms/UploadDocuments';
import SectionHeader from '../../components/SectionHeader';
import Wrapper from '../../components/Wrapper';
import useSchemaValidation from '../../hooks/useSchemaValidation';
import { fetch as fetchCountries } from '../../slices/countries';
import { fetch as fetchPropulsionSystemTypes } from '../../slices/propulsionSystemTypes';
import { create as createRequest, clearSuccess } from '../../slices/request';
import { fetch as fetchTypesOfServices } from '../../slices/typesOfServices';
import { fetch as fetchTypesOfShips } from '../../slices/typesOfShips';
import { useDispatch } from '../../store';
import getCombinedValidationSchema from '../../yupValidation';

const FormRequest = () => {
  let formikRef;
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showToastError, setShowToastError] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { countries, error: errorCountries } = useSelector(
    (state) => state.countries,
  );
  const { typesOfShips, error: errorTypesOfShips } = useSelector(
    (state) => state.typesOfShips,
  );
  const { typesOfServices, error: errorTypesOfServices } = useSelector(
    (state) => state.typesOfServices,
  );
  const { propulsionSystemTypes } = useSelector(
    (state) => state.propulsionSystemTypes,
  );
  const { isLoading, success, error } = useSelector((state) => state.request);

  const {
    requestSubmitterCountryValidationSchema,
    requestSubmitterEmailValidationSchema,
    requestSubmitterPhoneNumberValidationSchema,
    requestSubmitterCellPhoneValidationSchema,
    requestSubmitterPositionWithinCompanyValidationSchema,
    requestSubmitterFullNameValidationSchema,
    companyEmailValidationSchema,
    companyNameValidationSchema,
    companyCountryValidationSchema,
    companyStreetValidationSchema,
    companyMailBoxValidationSchema,
    companyNifValidationSchema,
    companyCityValidationSchema,
    companyPhoneNumberValidationSchema,
    companyCellPhoneValidationSchema,
    servicesTypesValidationSchema,
    additionalEletricityDescriptionValidationSchema,
    additionalCarpentryDescriptionValidationSchema,
    additionalBoilermakingDescriptionValidationSchema,
    additionalMechanicsDescriptionValidationSchema,
    additionalHullTreatmentDescriptionValidationSchema,
    additionaNonDestructiveTestingDescriptionValidationSchema,
    additionalMechanicalLocksmithingDescriptionValidationSchema,
    servicesTypesAndOtherServicesTypesValidationSchema,
    otherServiceValidationSchema,
    shipWidthValidationSchema,
    shipNameValidationSchema,
    shipLengthValidationSchema,
    shipYearValidationSchema,
    registrationPortValidationSchema,
    shipTypeAndOtherShipTypeValidationSchema,
    shipIMOValidationSchema,
    shipCountryValidationSchema,
    shipMaximumDisplacementValidationSchema,
    shipLightDisplacementValidationSchema,
    shipOverallLengthValidationSchema,
    shipDraftValidationSchema,
    shipDrafDepthValidationSchema,
    shipLightShipDraftValidationSchema,
    shipOnBoardElectricalPowerSystemVoltageValidationSchema,
    shipOnBoardElectricalPowerSystemFrequencyValidationSchema,
    shipBuildingYardsNameValidationSchema,
    shipBuildingYardsCountryValidationSchema,
    shipClassAndClassifyingValidationSchema,
    shipPropulsionSystemTypeIdValidationSchema,
    additionalInformationValidationSchema,
  } = useSchemaValidation();

  const fetchConstants = async () => {
    await Promise.all([
      dispatch(fetchCountries()),
      dispatch(fetchTypesOfShips()),
      dispatch(fetchTypesOfServices()),
      dispatch(fetchPropulsionSystemTypes()),
    ]);
  };

  const handleFetchRequest = async () => {
    await fetchConstants();
  };

  useEffect(() => {
    fetchConstants();
  }, [dispatch]);

  const initialValues = {
    requestSubmitterFullName: undefined,
    requestSubmitterEmail: undefined,
    requestSubmitterPhoneNumber: undefined,
    requestSubmitterCellPhone: undefined,
    requestSubmitterPositionWithinTheCompany: undefined,
    requestSubmitterCountry: undefined,
    companyName: undefined,
    companyEmail: undefined,
    companyPhoneNumber: undefined,
    companyCellPhone: undefined,
    companyNif: undefined,
    companyCountry: undefined,
    companyCity: undefined,
    companyStreet: undefined,
    companyMailBox: undefined,
    shipTypeID: undefined,
    otherShipType: undefined,
    shipIMO: undefined,
    shipName: undefined,
    shipCountry: undefined,
    shipRegistrationPort: undefined,
    shipMaximumDisplacement: undefined,
    shipLightDisplacement: undefined,
    shipLength: undefined,
    shipOverallLength: undefined,
    shipWidth: undefined,
    shipYear: undefined,
    shipDraft: undefined,
    shipDepth: undefined,
    shipPropulsionSystemTypeId: undefined,
    shipClassAndClassificationSocity: undefined, // opcional
    shipLightShipDraft: undefined,
    shipOnBoardElectricalPowerSystemVoltage: undefined,
    shipOnBoardElectricalPowerSystemFrequency: undefined,
    shipBuildingYardsName: undefined,
    shipBuildingYardsCountry: undefined,
    servicesTypes: [],
    serviceAdditionalDescriptionsOTHER: undefined, // optional
    serviceAdditionalDescriptionsELECTRICITY: undefined, // optional
    serviceAdditionalDescriptionsCARPENTRY: undefined, // optional
    serviceAdditionalDescriptionsBOILERMAKING: undefined, // optional
    serviceAdditionalDescriptionsMECHANICS: undefined, // optional
    serviceAdditionalDescriptionsHULL_TREATMENT: undefined, // optional
    serviceAdditionalDescriptionsNON_DESTRUCTIVE_TESTING: undefined, // optional
    serviceAdditionalDescriptionsMECHANICAL_LOCKSMITHING: undefined, // optional
    entryDate: undefined, // optional
    exitDate: undefined, // optional
    additionalInformation: undefined, // optional
  };

  const combinedValidationSchema = getCombinedValidationSchema([
    requestSubmitterCountryValidationSchema(),
    requestSubmitterEmailValidationSchema(),
    requestSubmitterPhoneNumberValidationSchema(),
    requestSubmitterCellPhoneValidationSchema(),
    requestSubmitterPositionWithinCompanyValidationSchema(),
    requestSubmitterFullNameValidationSchema(),
    companyNameValidationSchema(),
    companyNifValidationSchema(),
    companyMailBoxValidationSchema(),
    companyCityValidationSchema(),
    companyStreetValidationSchema(),
    companyCountryValidationSchema(),
    companyEmailValidationSchema(),
    companyPhoneNumberValidationSchema(),
    companyCellPhoneValidationSchema(),
    shipCountryValidationSchema(),
    shipNameValidationSchema(),
    shipWidthValidationSchema(),
    shipIMOValidationSchema(),
    shipMaximumDisplacementValidationSchema(),
    shipLightDisplacementValidationSchema(),
    shipLengthValidationSchema(),
    shipOverallLengthValidationSchema(),
    shipDraftValidationSchema(),
    shipDrafDepthValidationSchema(),
    shipLightShipDraftValidationSchema(),
    shipOnBoardElectricalPowerSystemVoltageValidationSchema(),
    shipOnBoardElectricalPowerSystemFrequencyValidationSchema(),
    shipBuildingYardsNameValidationSchema(),
    shipBuildingYardsCountryValidationSchema(),
    shipPropulsionSystemTypeIdValidationSchema(),
    shipYearValidationSchema(),
    servicesTypesValidationSchema(),
    additionalEletricityDescriptionValidationSchema(),
    additionalCarpentryDescriptionValidationSchema(),
    additionalBoilermakingDescriptionValidationSchema(),
    additionalMechanicsDescriptionValidationSchema(),
    additionalHullTreatmentDescriptionValidationSchema(),
    additionaNonDestructiveTestingDescriptionValidationSchema(),
    additionalMechanicalLocksmithingDescriptionValidationSchema(),
    otherServiceValidationSchema(),
    servicesTypesAndOtherServicesTypesValidationSchema(),
    registrationPortValidationSchema(),
    shipTypeAndOtherShipTypeValidationSchema(),
    shipClassAndClassifyingValidationSchema(),
    additionalInformationValidationSchema(),
  ]);

  const formatDate = (dateObj) => {
    const year = new Date(dateObj).getFullYear();
    const month = new Date(dateObj).getMonth() + 1;
    const day = new Date(dateObj).getDate();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const handleSubmitForm = async (data) => {
    const dataToSend = {
      ...data,
      entryDate: data.entryDate ? formatDate(data.entryDate) : null,
      exitDate: data.exitDate ? formatDate(data.exitDate) : null,
    };

    await dispatch(createRequest(dataToSend));
  };

  const handleExternalSubmit = () => {
    if (formikRef) {
      formikRef.handleSubmit();
    }
  };

  useEffect(() => {
    if (success) {
      navigate('/sucesso');
      dispatch(clearSuccess());
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (error) {
      setShowToastError(true);
    }
  }, [error, dispatch]);

  const handleCloseToast = () => {
    setShowToastError(false);
  };

  const renderForm = () => {
    if (countries && typesOfShips && typesOfServices) {
      return (
        <>
          <SectionHeader
            title={intl.formatMessage({
              id: 'HOME.FORM_REQUEST_TITLE',
            })}
            description={intl.formatMessage({
              id: 'HOME.FORM_REQUEST_DESCRIPTION',
            })}
            isPrimary
          />
          <Box sx={{ mt: 3 }}>
            <ContentCard>
              <Box>
                <Formik
                  /* eslint-disable no-return-assign */
                  innerRef={(ref) => (formikRef = ref)}
                  initialValues={initialValues}
                  validationSchema={combinedValidationSchema}
                  onSubmit={(values) => {
                    const newValues = { ...values, files: selectedFiles };
                    handleSubmitForm(newValues);
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 3,
                          flexDirection: 'column',
                          p: 3,
                        }}
                      >
                        <RequestSubmitterInformation
                          values={values}
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          countries={countries}
                        />

                        <ShipOwnerInformation
                          values={values}
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          countries={countries}
                        />

                        <ShipInformation
                          values={values}
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          countries={countries}
                          typesOfShips={typesOfShips}
                          propulsionSystemTypes={propulsionSystemTypes}
                        />

                        <ServiceInformations
                          typesOfServices={typesOfServices}
                          handleChange={handleChange}
                          errors={errors}
                          values={values}
                          touched={touched}
                          setFieldValue={setFieldValue}
                        />

                        <UploadDocuments
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          handleFileChange={handleChange}
                          values={values}
                        />

                        <AdditionalInformation
                          values={values}
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        {/* Button Area */}
                        <Box sx={{}}>
                          <CustomButton
                            onClick={handleExternalSubmit}
                            title={intl.formatMessage({
                              id: 'ACTIONS.SUBMIT',
                            })}
                            isLoading={isLoading}
                          />
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </ContentCard>
          </Box>
          <CustomSnackBar
            open={showToastError}
            severity="error"
            message="Ocorreu um erro."
            onClose={handleCloseToast}
          />
        </>
      );
    }

    if (errorCountries || errorTypesOfServices || errorTypesOfShips) {
      return <Error onClick={() => handleFetchRequest()} />;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Formulário Cliente | Cabnave</title>
      </Helmet>
      <Wrapper>{renderForm()}</Wrapper>
    </>
  );
};

export default FormRequest;
