import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../lib/axios';

const initialState = {
  typesOfServices: null,
  isLoading: false,
  error: null,
  success: null,
};

const slice = createSlice({
  name: 'serviceTypes',
  initialState,
  reducers: {
    _get: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    _getSuccess: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        typesOfServices: payload,
      };
    },
    _getByIdFailure: (state) => {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    },
  },
});

const { _get, _getSuccess, _getFailure } = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'types_of_services/fetch',
  async (id, { dispatch }) => {
    try {
      dispatch(_get());
      const { data } = await axios.get('/servicetypes');

      const sortedData = [...data].sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.id === 'OTHER' ? 1 : b.id === 'OTHER' ? -1 : 0,
      );

      dispatch(_getSuccess(sortedData));
    } catch (error) {
      dispatch(_getFailure());
    }
  },
);
