import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import theme from '../../../theme';
import AutocompleteInput from '../../Inputs/Autocomplete';
import TextInput from '../../Inputs/TextInput';

const RequestSubmitterInformation = ({
  values,
  errors,
  touched,
  setFieldValue,
  countries,
  onChange,
  onBlur,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'REQUEST_FORM.REQUEST_SUBMITTER',
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <TextInput
          name="requestSubmitterFullName"
          error={Boolean(
            touched.requestSubmitterFullName && errors.requestSubmitterFullName,
          )}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.REQUEST_SUBMITTER.FULL_NAME',
          })}
          helperText={
            touched.requestSubmitterFullName && errors.requestSubmitterFullName
          }
          onChange={onChange}
          value={values.requestSubmitterFullName}
          onBlur={onBlur}
        />
        <TextInput
          name="requestSubmitterEmail"
          error={Boolean(
            touched.requestSubmitterEmail && errors.requestSubmitterEmail,
          )}
          label={intl.formatMessage({
            id: 'EMAIL',
          })}
          helperText={
            touched.requestSubmitterEmail && errors.requestSubmitterEmail
          }
          onChange={onChange}
          value={values.requestSubmitterEmail}
          onBlur={onBlur}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <TextInput
          name="requestSubmitterPhoneNumber"
          error={Boolean(
            touched.requestSubmitterPhoneNumber &&
              errors.requestSubmitterPhoneNumber,
          )}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.REQUEST_SUBMITTER.PHONE_NUMBER',
          })}
          helperText={
            touched.requestSubmitterPhoneNumber &&
            errors.requestSubmitterPhoneNumber
          }
          onChange={onChange}
          value={values.requestSubmitterPhoneNumber}
          onBlur={onBlur}
        />
        <TextInput
          name="requestSubmitterCellPhone"
          error={Boolean(
            touched.requestSubmitterCellPhone &&
              errors.requestSubmitterCellPhone,
          )}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.REQUEST_SUBMITTER.CELL_PHONE',
          })}
          helperText={
            touched.requestSubmitterCellPhone &&
            errors.requestSubmitterCellPhone
          }
          onChange={onChange}
          value={values.requestSubmitterCellPhone}
          onBlur={onBlur}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          mt: 2,
          flexDirection: 'column',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <TextInput
            name="requestSubmitterPositionWithinTheCompany"
            error={Boolean(
              touched.requestSubmitterPositionWithinTheCompany &&
                errors.requestSubmitterPositionWithinTheCompany,
            )}
            label={intl.formatMessage({
              id: 'REQUEST_FORM.REQUEST_SUBMITTER.POSITION_WITHIN_THE_COMPANY',
            })}
            helperText={
              touched.requestSubmitterPositionWithinTheCompany &&
              errors.requestSubmitterPositionWithinTheCompany
            }
            onChange={onChange}
            value={values.requestSubmitterPositionWithinTheCompany}
            onBlur={onBlur}
          />
          <AutocompleteInput
            name="requestSubmitterCountry"
            options={countries || []}
            getLabel={(country) => country.name}
            value={values.requestSubmitterCountry}
            label={intl.formatMessage({
              id: 'REQUEST_FORM.REQUEST_SUBMITTER.COUNTRY',
            })}
            error={Boolean(
              touched.requestSubmitterCountry && errors.requestSubmitterCountry,
            )}
            helperText={
              touched.requestSubmitterCountry && errors.requestSubmitterCountry
            }
            onChange={(newValue) =>
              setFieldValue('requestSubmitterCountry', newValue)
            }
            onBlur={onBlur}
          />
        </Box>
      </Box>
    </Box>
  );
};

RequestSubmitterInformation.propTypes = {
  values: PropTypes.shape({
    requestSubmitterFullName: PropTypes.arrayOf(PropTypes.string),
    requestSubmitterEmail: PropTypes.arrayOf(PropTypes.string),
    requestSubmitterPhoneNumber: PropTypes.arrayOf(PropTypes.string),
    requestSubmitterCellPhone: PropTypes.arrayOf(PropTypes.string),
    requestSubmitterPositionWithinTheCompany: PropTypes.arrayOf(
      PropTypes.string,
    ),
    requestSubmitterCountry: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  errors: PropTypes.shape({
    requestSubmitterFullName: PropTypes.string,
    requestSubmitterEmail: PropTypes.string,
    requestSubmitterPhoneNumber: PropTypes.string,
    requestSubmitterCellPhone: PropTypes.string,
    companyNif: PropTypes.string,
    requestSubmitterPositionWithinTheCompany: PropTypes.string,
    requestSubmitterCountry: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    requestSubmitterFullName: PropTypes.arrayOf(PropTypes.string),
    requestSubmitterEmail: PropTypes.arrayOf(PropTypes.string),
    requestSubmitterPhoneNumber: PropTypes.arrayOf(PropTypes.string),
    requestSubmitterCellPhone: PropTypes.arrayOf(PropTypes.string),
    requestSubmitterPositionWithinTheCompany: PropTypes.arrayOf(
      PropTypes.string,
    ),
    requestSubmitterCountry: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isoCode: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default RequestSubmitterInformation;
