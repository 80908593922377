import { Box, FormLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import theme from '../../../theme';
import AutocompleteInput from '../../Inputs/Autocomplete';
import NumericTextField from '../../Inputs/NumericTextField';
import TextInput from '../../Inputs/TextInput';

const ShipOwnerInformation = ({
  values,
  errors,
  touched,
  setFieldValue,
  countries,
  onChange,
  onBlur,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'REQUEST_FORM.SHIPOWNER_INFORMATION',
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <TextInput
          name="companyName"
          error={Boolean(touched.companyName && errors.companyName)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIPOWNER_INFORMATION.COMPANY_NAME',
          })}
          helperText={touched.companyName && errors.companyName}
          onChange={onChange}
          value={values.companyName}
          onBlur={onBlur}
        />
        <TextInput
          name="companyEmail"
          error={Boolean(touched.companyEmail && errors.companyEmail)}
          label={intl.formatMessage({
            id: 'EMAIL',
          })}
          helperText={touched.companyEmail && errors.companyEmail}
          onChange={onChange}
          value={values.companyEmail}
          onBlur={onBlur}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <TextInput
          name="companyPhoneNumber"
          error={Boolean(
            touched.companyPhoneNumber && errors.companyPhoneNumber,
          )}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIPOWNER_INFORMATION.COMPANY_PHONE_NUMBER',
          })}
          helperText={touched.companyPhoneNumber && errors.companyPhoneNumber}
          onChange={onChange}
          value={values.companyPhoneNumber}
          onBlur={onBlur}
        />
        <TextInput
          name="companyCellPhone"
          error={Boolean(touched.companyCellPhone && errors.companyCellPhone)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIPOWNER_INFORMATION.COMPANY_CELL_PHONE',
          })}
          helperText={touched.companyCellPhone && errors.companyCellPhone}
          onChange={onChange}
          value={values.companyCellPhone}
          onBlur={onBlur}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <NumericTextField
          name="companyNif"
          error={Boolean(touched.companyNif && errors.companyNif)}
          fullWidth
          helperText={touched.companyNif && errors.companyNif}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIPOWNER_INFORMATION.COMPANY_NIF',
          })}
          onChange={onChange}
          value={values.companyNif}
          variant="outlined"
          onBlur={onBlur}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          mt: 2,
          flexDirection: 'column',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <FormLabel component="legend">
          {intl.formatMessage({
            id: 'REQUEST_FORM.SHIPOWNER_INFORMATION.COMPANY_ADDRESS.LABEL',
          })}
        </FormLabel>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <AutocompleteInput
            name="companyCountry"
            options={countries || []}
            getLabel={(country) => country.name}
            value={values.companyCountry}
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SHIPOWNER_INFORMATION.COMPANY_COUNTRY',
            })}
            error={Boolean(touched.companyCountry && errors.companyCountry)}
            helperText={touched.companyCountry && errors.companyCountry}
            onChange={(newValue) => setFieldValue('companyCountry', newValue)}
            onBlur={onBlur}
          />
          <TextInput
            name="companyCity"
            error={Boolean(touched.companyCity && errors.companyCity)}
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SHIPOWNER_INFORMATION.COMPANY_CITY',
            })}
            helperText={touched.companyCity && errors.companyCity}
            onChange={onChange}
            value={values.companyCity}
            onBlur={onBlur}
          />
          <TextInput
            name="companyStreet"
            error={Boolean(touched.companyStreet && errors.companyStreet)}
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SHIPOWNER_INFORMATION.COMPANY_STREET',
            })}
            helperText={touched.companyStreet && errors.companyStreet}
            onChange={onChange}
            value={values.companyStreet}
            onBlur={onBlur}
          />
          <TextInput
            name="companyMailBox"
            error={Boolean(touched.companyMailBox && errors.companyMailBox)}
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SHIPOWNER_INFORMATION.COMPANY_MAIL_BOX',
            })}
            helperText={touched.companyMailBox && errors.companyMailBox}
            onChange={onChange}
            value={values.companyMailBox}
            onBlur={onBlur}
          />
        </Box>
      </Box>
    </Box>
  );
};

ShipOwnerInformation.propTypes = {
  values: PropTypes.shape({
    companyName: PropTypes.arrayOf(PropTypes.string),
    companyEmail: PropTypes.arrayOf(PropTypes.string),
    companyPhoneNumber: PropTypes.arrayOf(PropTypes.string),
    companyCellPhone: PropTypes.arrayOf(PropTypes.string),
    companyNif: PropTypes.arrayOf(PropTypes.string),
    companyCountry: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    companyCity: PropTypes.arrayOf(PropTypes.string),
    companyStreet: PropTypes.arrayOf(PropTypes.string),
    companyMailBox: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  errors: PropTypes.shape({
    companyName: PropTypes.string,
    companyEmail: PropTypes.string,
    companyPhoneNumber: PropTypes.string,
    companyCellPhone: PropTypes.string,
    companyNif: PropTypes.string,
    companyCountry: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    companyName: PropTypes.arrayOf(PropTypes.string),
    companyEmail: PropTypes.arrayOf(PropTypes.string),
    companyPhoneNumber: PropTypes.arrayOf(PropTypes.string),
    companyCellPhone: PropTypes.arrayOf(PropTypes.string),
    companyNif: PropTypes.arrayOf(PropTypes.string),
    companyCountry: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    companyCity: PropTypes.arrayOf(PropTypes.string),
    companyStreet: PropTypes.arrayOf(PropTypes.string),
    companyMailBox: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isoCode: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default ShipOwnerInformation;
