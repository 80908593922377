import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import AdditionalInformation from '../../components/AdditionalInformation';
import CustomButton from '../../components/Button/CustomButton';
import ContentCard from '../../components/ContentCard';
import CustomSnackBar from '../../components/CustomSnackBar';
import DocumentsList from '../../components/DocumentsList';
import Error from '../../components/Error';
import FileList from '../../components/FileList';
import NoData from '../../components/NoData';
import SectionHeader from '../../components/SectionHeader';
import ServicesInformation from '../../components/ServicesInformation';
import InformationTable from '../../components/Table/InformationTable';
import StatusInformation from '../../components/Table/StatusInformation';
import Wrapper from '../../components/Wrapper';
import { requestChatSession } from '../../slices/chat';
import { fetchById, fetchFiles } from '../../slices/request';
import { useDispatch, useSelector } from '../../store';

const ViewRequest = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { request, isLoading, error, files, isLoadingFiles } = useSelector(
    (state) => state.request,
  );
  const { isLoading: openChat, success: chatSuccess } = useSelector(
    (state) => state.chat,
  );
  const [showToast, setShowToast] = useState(false);

  const handleOpenChat = async () => {
    await dispatch(requestChatSession());
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const handleFetchRequest = () => {
    dispatch(fetchById(id));
  };

  const renderAdditionalInformation = () => {
    if (request.observation) {
      return <AdditionalInformation request={request} />;
    }
    return null;
  };

  const renderDocumentList = (type) => {
    if (request.samba_documents) {
      return (
        <Box sx={{ mt: 2 }}>
          <DocumentsList documents={request.samba_documents} listType={type} />
        </Box>
      );
    }

    return null;
  };

  const renderFileList = () => {
    if (!isLoadingFiles && files) {
      return (
        <Box>
          <SectionHeader
            title="Documentos"
            description="Detalhes sobre os documentos do cliente"
          />
          <Box sx={{ mt: 2 }}>
            <FileList files={files} />
          </Box>
        </Box>
      );
    }

    return (
      <ContentCard>
        <TableContainer>
          <Table>
            <TableBody>
              <TableCell
                sx={{
                  border: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress size={24} />
              </TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      </ContentCard>
    );
  };

  useEffect(() => {
    if (request) {
      dispatch(fetchFiles(id));
    }
  }, [request]);

  useEffect(() => {
    dispatch(fetchById(id));
  }, [dispatch]);

  useEffect(() => {
    if (chatSuccess) {
      setShowToast(true);
    }
  }, [chatSuccess, dispatch]);

  const renderClient = () => {
    if (request && !isLoading) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box>
            <SectionHeader
              title="Opçōes do Pedido"
              description="Opções que pode fazer no pedido"
            />
            <Box
              sx={{
                flexDirection: 'row',
                display: 'flex',
                mt: 2,
                justifyContent: 'flex-end',
              }}
            >
              <Box
                sx={{
                  width: '200px',
                  '@media (max-width: 743px)': {
                    width: '100%',
                  },
                }}
              >
                <CustomButton
                  title="Abrir Conversa"
                  color="primary"
                  isLoading={openChat}
                  onClick={handleOpenChat}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <SectionHeader
              title="Orçamento do Pedido"
              description="Detalhes sobre o orçamento do pedido"
            />
            {renderDocumentList('pro-formas')}
          </Box>
          <Box>
            <SectionHeader
              title="Pagamentos"
              description="Detalhes sobre os pagamentos do pedido"
            />
            {renderDocumentList('invoice_receipts')}
          </Box>
          {renderFileList()}
          <Box>
            <SectionHeader
              title="Detalhes do Estado"
              description="Informações sobre o estado da solicitaçāo"
            />
            <Box sx={{ mt: 2 }}>
              <StatusInformation request={request} />
            </Box>
          </Box>

          <Box sx={{ width: '100%' }}>
            <SectionHeader
              title="Detalhes do Pedido"
              description="Informações detalhadas do pedido"
            />
            <Box sx={{ mt: 2 }}>
              <InformationTable item={request} type="order" />
            </Box>
          </Box>
          <Box>
            <SectionHeader
              title="Pessoa Responsável Pelo Preenchimento do Formulário"
              description="Informações sobre a pessoa responsável pelo preenchimento do formulário"
            />
            <Box sx={{ mt: 2 }}>
              <InformationTable item={request} type="work_order_submitter" />
            </Box>
          </Box>

          <Box sx={{ width: '100%' }}>
            <SectionHeader
              title="Detalhes do Armador"
              description="Informações sobre o armador do navio"
            />
            <Box sx={{ mt: 2 }}>
              <InformationTable item={request} type="owner" />
            </Box>
          </Box>

          <Box>
            <SectionHeader
              title="Detalhes do Serviço"
              description="Serviços a prestar que o cliente solicitou"
            />
            <Box
              sx={{
                mt: 2,
              }}
            >
              <ServicesInformation services={request.services} />
            </Box>
          </Box>

          <Box>
            <SectionHeader
              title="Detalhes do Navio"
              description="Informações detalhadas do novo"
            />
            <Box sx={{ mt: 2 }}>
              <InformationTable item={request} type="ship" />
            </Box>
          </Box>

          <Box>{renderAdditionalInformation()}</Box>
        </Box>
      );
    }
    if (!request && !isLoading) {
      return <NoData message="Solicitaçāo nāo encontrada" />;
    }

    if (error) {
      return <Error onClick={() => handleFetchRequest()} />;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Solicitaçōes | Cabnave</title>
      </Helmet>
      <Wrapper>
        <SectionHeader
          title="Solicitaçāo"
          description="Informaçōes detalhadas da sua solicitaçāo"
          isPrimary
        />
        <Box sx={{ mt: 3 }}>{renderClient()}</Box>
        <CustomSnackBar
          open={showToast}
          severity="success"
          message="Visite sua caixa de entrada de e-mail para obter acesso ao chat."
          onClose={handleCloseToast}
        />
      </Wrapper>
    </>
  );
};

export default ViewRequest;
