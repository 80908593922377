import { Box, FormLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import theme from '../../../theme';
import AutocompleteInput from '../../Inputs/Autocomplete';
import NumericTextField from '../../Inputs/NumericTextField';
import RadioGroupInput from '../../Inputs/RadioGroup';
import TextInput from '../../Inputs/TextInput';

const ShipInformation = ({
  values,
  errors,
  touched,
  setFieldValue,
  countries,
  typesOfShips,
  propulsionSystemTypes,
  onChange,
  onBlur,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION',
          })}
        </Typography>
      </Box>

      <Box>
        <RadioGroupInput
          name="shipTypeID"
          value={values.shipTypeID}
          error={Boolean(touched.shipTypeID && errors.shipTypeID)}
          touched={touched.shipTypeID}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.TYPE_OF_SHIP',
          })}
          helperText={touched.shipTypeID && errors.shipTypeID}
          onChange={onChange}
          options={typesOfShips || []}
        />
      </Box>

      {values.shipTypeID === 'OTHER' && (
        <Box sx={{ mb: 2 }}>
          <TextInput
            name="otherShipType"
            error={Boolean(touched.otherShipType && errors.otherShipType)}
            label={intl.formatMessage({
              id: 'OTHER',
            })}
            helperText={touched.otherShipType && errors.otherShipType}
            onChange={onChange}
            onBlur={onBlur}
            value={values.otherShipType}
          />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <NumericTextField
          name="shipIMO"
          error={Boolean(touched.shipIMO && errors.shipIMO)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.IMO',
          })}
          helperText={touched.shipIMO && errors.shipIMO}
          onChange={onChange}
          value={values.shipIMO}
          onBlur={onBlur}
        />
        <TextInput
          name="shipName"
          error={Boolean(touched.shipName && errors.shipName)}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_NAME',
          })}
          helperText={touched.shipName && errors.shipName}
          onChange={onChange}
          value={values.shipName}
          onBlur={onBlur}
        />
        <AutocompleteInput
          name="shipCountry"
          options={countries || []}
          getLabel={(country) => country.name}
          value={values.shipCountry}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.COUNTRY',
          })}
          error={Boolean(touched.shipCountry && errors.shipCountry)}
          helperText={touched.shipCountry && errors.shipCountry}
          onChange={(newValue) => setFieldValue('shipCountry', newValue)}
          onBlur={onBlur}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <TextInput
          name="shipRegistrationPort"
          error={Boolean(
            touched.shipRegistrationPort && errors.shipRegistrationPort,
          )}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_REGISTRATION_PORT',
          })}
          helperText={
            touched.shipRegistrationPort && errors.shipRegistrationPort
          }
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipRegistrationPort}
        />

        <NumericTextField
          name="shipMaximumDisplacement"
          error={Boolean(
            touched.shipMaximumDisplacement && errors.shipMaximumDisplacement,
          )}
          fullWidth
          helperText={
            touched.shipMaximumDisplacement && errors.shipMaximumDisplacement
          }
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_MAXIMUM_DISPLACEMENT',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipMaximumDisplacement}
          variant="outlined"
        />

        <NumericTextField
          name="shipLightDisplacement"
          error={Boolean(
            touched.shipLightDisplacement && errors.shipLightDisplacement,
          )}
          fullWidth
          helperText={
            touched.shipLightDisplacement && errors.shipLightDisplacement
          }
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_LIGHT_DISPLACEMENT',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipLightDisplacement}
          variant="outlined"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <NumericTextField
          name="shipLength"
          error={Boolean(touched.shipLength && errors.shipLength)}
          fullWidth
          helperText={touched.shipLength && errors.shipLength}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_LENGTH',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipLength}
          variant="outlined"
        />

        <NumericTextField
          name="shipOverallLength"
          error={Boolean(touched.shipOverallLength && errors.shipOverallLength)}
          fullWidth
          helperText={touched.shipOverallLength && errors.shipOverallLength}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_OVERALL_LENGTH',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipOverallLength}
          variant="outlined"
        />

        <NumericTextField
          name="shipWidth"
          error={Boolean(touched.shipWidth && errors.shipWidth)}
          fullWidth
          helperText={touched.shipWidth && errors.shipWidth}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_WIDTH',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipWidth}
          variant="outlined"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <NumericTextField
          name="shipYear"
          error={Boolean(touched.shipYear && errors.shipYear)}
          fullWidth
          helperText={touched.shipYear && errors.shipYear}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_YEAR',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipYear}
          variant="outlined"
        />

        <NumericTextField
          name="shipDraft"
          error={Boolean(touched.shipDraft && errors.shipDraft)}
          fullWidth
          helperText={touched.shipDraft && errors.shipDraft}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_DRAFT',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipDraft}
          variant="outlined"
        />

        <NumericTextField
          name="shipDepth"
          error={Boolean(touched.shipDepth && errors.shipDepth)}
          fullWidth
          helperText={touched.shipDepth && errors.shipDepth}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_DEPTH',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipDepth}
          variant="outlined"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <AutocompleteInput
          name="shipPropulsionSystemTypeId"
          options={propulsionSystemTypes || []}
          getLabel={(propulsionSystem) =>
            intl.formatMessage({
              id: `REQUEST_FORM_SHIP_PROPULSION_SYSTEM_OPTION_${propulsionSystem.id}`,
            })
          }
          value={values.shipPropulsionSystemTypeId}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_PROPULSION_SYSTEM_TYPE_ID',
          })}
          error={Boolean(
            touched.shipPropulsionSystemTypeId &&
              errors.shipPropulsionSystemTypeId,
          )}
          helperText={
            touched.shipPropulsionSystemTypeId &&
            errors.shipPropulsionSystemTypeId
          }
          onChange={(newValue) =>
            setFieldValue('shipPropulsionSystemTypeId', newValue)
          }
          onBlur={onBlur}
        />

        <TextInput
          name="shipClassAndClassificationSocity"
          error={Boolean(
            touched.shipClassAndClassificationSocity &&
              errors.shipClassAndClassificationSocity,
          )}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_CLASS_AND_CLASSIFICATION_SOCITY',
          })}
          helperText={
            touched.shipClassAndClassificationSocity &&
            errors.shipClassAndClassificationSocity
          }
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipClassAndClassificationSocity}
        />

        <NumericTextField
          name="shipLightShipDraft"
          error={Boolean(
            touched.shipLightShipDraft && errors.shipLightShipDraft,
          )}
          fullWidth
          helperText={touched.shipLightShipDraft && errors.shipLightShipDraft}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_LIGHT_SHIP_DRAFT',
          })}
          onChange={onChange}
          onBlur={onBlur}
          value={values.shipLightShipDraft}
          variant="outlined"
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <FormLabel component="legend">
          {intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.ON_BOARD_ELECTRICAL_POWER_SYSTEM',
          })}
        </FormLabel>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          mt: 2,
          flexDirection: 'column',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <TextInput
            name="shipOnBoardElectricalPowerSystemVoltage"
            error={Boolean(
              touched.shipOnBoardElectricalPowerSystemVoltage &&
                errors.shipOnBoardElectricalPowerSystemVoltage,
            )}
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SHIP_INFORMATION.ON_BOARD_ELECTRICAL_POWER_SYSTEM.VOLTAGE',
            })}
            helperText={
              touched.shipOnBoardElectricalPowerSystemVoltage &&
              errors.shipOnBoardElectricalPowerSystemVoltage
            }
            onChange={onChange}
            value={values.shipOnBoardElectricalPowerSystemVoltage}
            onBlur={onBlur}
          />
          <NumericTextField
            name="shipOnBoardElectricalPowerSystemFrequency"
            error={Boolean(
              touched.shipOnBoardElectricalPowerSystemFrequency &&
                errors.shipOnBoardElectricalPowerSystemFrequency,
            )}
            fullWidth
            helperText={
              touched.shipOnBoardElectricalPowerSystemFrequency &&
              errors.shipOnBoardElectricalPowerSystemFrequency
            }
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SHIP_INFORMATION.ON_BOARD_ELECTRICAL_POWER_SYSTEM.FREQUENCY',
            })}
            onChange={onChange}
            onBlur={onBlur}
            value={values.shipOnBoardElectricalPowerSystemFrequency}
            variant="outlined"
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <FormLabel component="legend">
          {intl.formatMessage({
            id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_BUILDING_YARDS',
          })}
        </FormLabel>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          mt: 2,
          flexDirection: 'column',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          }}
        >
          <AutocompleteInput
            name="shipBuildingYardsCountry"
            options={countries || []}
            getLabel={(country) => country.name}
            value={values.shipBuildingYardsCountry}
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_BUILDING_YARDS.COUNTRY',
            })}
            error={Boolean(
              touched.shipBuildingYardsCountry &&
                errors.shipBuildingYardsCountry,
            )}
            helperText={
              touched.shipBuildingYardsCountry &&
              errors.shipBuildingYardsCountry
            }
            onChange={(newValue) =>
              setFieldValue('shipBuildingYardsCountry', newValue)
            }
            onBlur={onBlur}
          />
          <TextInput
            name="shipBuildingYardsName"
            error={Boolean(
              touched.shipBuildingYardsName && errors.shipBuildingYardsName,
            )}
            label={intl.formatMessage({
              id: 'REQUEST_FORM.SHIP_INFORMATION.SHIP_BUILDING_YARDS.NAME',
            })}
            helperText={
              touched.shipBuildingYardsName && errors.shipBuildingYardsName
            }
            onChange={onChange}
            value={values.shipBuildingYardsName}
            onBlur={onBlur}
          />
        </Box>
      </Box>
    </Box>
  );
};

ShipInformation.propTypes = {
  values: PropTypes.shape({
    companyName: PropTypes.arrayOf(PropTypes.string),
    companyEmail: PropTypes.arrayOf(PropTypes.string),
    companyPhoneNumber: PropTypes.arrayOf(PropTypes.string),
    companyCellPhone: PropTypes.arrayOf(PropTypes.string),
    companyNif: PropTypes.arrayOf(PropTypes.string),
    companyCountry: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    companyCity: PropTypes.arrayOf(PropTypes.string),
    companyStreet: PropTypes.arrayOf(PropTypes.string),
    companyMailBox: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  errors: PropTypes.shape({
    companyName: PropTypes.string,
    companyEmail: PropTypes.string,
    companyPhoneNumber: PropTypes.string,
    companyCellPhone: PropTypes.string,
    companyNif: PropTypes.string,
    companyCountry: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    companyName: PropTypes.arrayOf(PropTypes.string),
    companyEmail: PropTypes.arrayOf(PropTypes.string),
    companyPhoneNumber: PropTypes.arrayOf(PropTypes.string),
    companyCellPhone: PropTypes.arrayOf(PropTypes.string),
    companyNif: PropTypes.arrayOf(PropTypes.string),
    companyCountry: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    companyCity: PropTypes.arrayOf(PropTypes.string),
    companyStreet: PropTypes.arrayOf(PropTypes.string),
    companyMailBox: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isoCode: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  typesOfShips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  propulsionSystemTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default ShipInformation;
