import { Box, Typography, useTheme, CircularProgress } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';

import NoMessages from '../NoMessages';

const ChatMessage = ({ data, isLoading }) => {
  const theme = useTheme();

  const render = () => {
    if (isLoading) {
      return (
        <Box
          sx={{
            display: 'flex',
            height: '64vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (data.length === 0 && !isLoading) {
      return (
        <Box
          sx={{
            display: 'flex',
            height: '64vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <NoMessages message="Sem mensagens" />
        </Box>
      );
    }

    return data.map((message) => {
      return (
        <Box
          key={message.id}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: message.is_admin ? 'row' : 'row-reverse',
            mb: 2,
          }}
        >
          <Box>
            <Box
              sx={{
                position: 'relative',
                backgroundColor: message.is_admin
                  ? '#fff'
                  : theme.palette.primary.main,
                boxShadow: 'subtle',
                py: 1,
                px: 2,
                borderRadius: '8px',
                minWidth: 200,
                maxWidth: 600,
                wordBreak: 'break-word',
              }}
            >
              <Typography
                variant="body2"
                color={message.is_admin ? '#262626' : '#fff'}
              >
                {message.is_admin ? 'CABNAVE' : 'Eu'}
              </Typography>
              <Typography
                sx={{ whiteSpace: 'pre-wrap' }}
                color={message.is_admin ? '#262626' : '#fff'}
              >
                {message.message}
              </Typography>
            </Box>
            <Typography variant="subtitle2">
              {formatDistanceToNow(message.time, {
                addSuffix: true,
                locale: ptBR,
              })}
            </Typography>
          </Box>
        </Box>
      );
    });
  };

  return <Box sx={{ height: '100%' }}>{render()}</Box>;
};

ChatMessage.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      room_id: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      is_admin: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default ChatMessage;
