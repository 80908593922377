import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import chatAxios from '../lib/chatAxios';

const initialState = {
  chat: null,
  isLoading: false,
  error: null,
  success: null,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    _get: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    _getSuccess: (state, { payload }) => {
      return {
        ...state,
        chat: payload,
        isLoading: false,
        error: false,
        success: true,
      };
    },
    _getFailure: (state) => {
      return {
        ...state,
        chat: null,
        isLoading: false,
        error: true,
      };
    },
  },
});

const { _get, _getSuccess, _getFailure } = slice.actions;

export default slice.reducer;

export const requestChatSession = createAsyncThunk(
  'chat/fetch',
  async (_, { dispatch, getState }) => {
    const requestId = getState().request.request.id;
    dispatch(_get());
    try {
      const { data } = await chatAxios.post(`/rooms/${requestId}/sessions`);

      dispatch(_getSuccess(data));
    } catch (error) {
      dispatch(_getFailure());
    }
  },
);
