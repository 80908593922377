import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import theme from '../../../theme';
import TextInput from '../../Inputs/TextInput';

const AdditionalInformation = ({
  values,
  errors,
  touched,
  onChange,
  onBlur,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'REQUEST_FORM.ADDITIONAL_INFORMATION',
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          mt: 2,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        }}
      >
        <TextInput
          name="additionalInformation"
          error={Boolean(
            touched.additionalInformation && errors.additionalInformation,
          )}
          label={intl.formatMessage({
            id: 'REQUEST_FORM.ADDITIONAL_INFORMATION.OBSERVATIONS',
          })}
          helperText={
            touched.additionalInformation && errors.additionalInformation
          }
          onChange={onChange}
          value={values.additionalInformation}
          onBlur={onBlur}
          multiline={4}
          minRows={4}
        />
      </Box>
    </Box>
  );
};

AdditionalInformation.propTypes = {
  values: PropTypes.shape({
    additionalInformation: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  errors: PropTypes.shape({
    additionalInformation: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    additionalInformation: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default AdditionalInformation;
